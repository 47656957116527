<template>
  <div>
    <el-table
      :border="true"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column
        type="selection"
        width="55"
      ></el-table-column>

      <el-table-column align="center"
        :label="fields.questionType.label"
        :prop="fields.questionType.name"
        sortable="custom" 
      >
        <template slot-scope="scope">
          {{ presenter(scope.row, 'questionType') }}
        </template>
      </el-table-column>

      <el-table-column align="center"
        :label="fields.autoScore.label"
        :prop="fields.autoScore.name"
        sortable="custom"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'autoScore')
        }}</template>
      </el-table-column>

      <el-table-column align="center"
        :label="fields.section.label"
        :prop="fields.section.name"
        sortable="custom"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'section')
        }}</template>
      </el-table-column>

      <!--      <el-table-column-->
      <!--        :label="fields.description.label"-->
      <!--        :prop="fields.description.name"-->
      <!--        sortable="custom"-->
      <!--      >-->
      <!--        <template slot-scope="scope">-->
      <!--          {{ presenter(scope.row, 'description') }}-->
      <!--        </template>-->
      <!--      </el-table-column>-->

      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        width="180"
      >
        <template slot-scope="scope">
          <div class="table-actions">
            <router-link
              :to="`/question-type/${scope.row.id}`"
            >
              <el-button type="text">
                <app-i18n code="common.view"></app-i18n>
              </el-button>
            </router-link>

            <router-link
              :to="`/question-type/${scope.row.id}/edit`"
              v-if="hasPermissionToEdit"
            >
              <el-button type="text">
                <app-i18n code="common.edit"></app-i18n>
              </el-button>
            </router-link>

            <el-button
              :disabled="destroyLoading"
              @click="doDestroyWithConfirm(scope.row.id)"
              type="text"
              v-if="hasPermissionToDestroy"
            >
              <app-i18n code="common.destroy"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { QuestionTypeModel } from '@/modules/question-type/question-type-model';
import { mapGetters, mapActions } from 'vuex';
import { QuestionTypePermissions } from '@/modules/question-type/question-type-permissions';
import { i18n } from '@/i18n';

const { fields } = QuestionTypeModel;

export default {
  name: 'app-question-type-list-table',

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'questionType/list/rows',
      count: 'questionType/list/count',
      loading: 'questionType/list/loading',
      pagination: 'questionType/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'questionType/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new QuestionTypePermissions(this.currentUser)
        .edit;
    },

    hasPermissionToDestroy() {
      return new QuestionTypePermissions(this.currentUser)
        .destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'questionType/list/doChangeSort',
      doChangePaginationCurrentPage:
        'questionType/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'questionType/list/doChangePaginationPageSize',
      doMountTable: 'questionType/list/doMountTable',
      doDestroy: 'questionType/destroy/doDestroy',
    }),

    presenter(row, fieldName) {
      return QuestionTypeModel.presenter(row, fieldName);
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
