<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.questionType.menu"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.questionType.list.title"
        ></app-i18n>
      </h1>

<!--      <app-question-type-list-toolbar></app-question-type-list-toolbar>-->
      <app-question-type-list-filter></app-question-type-list-filter>
      <app-question-type-list-table></app-question-type-list-table>
    </div>
  </div>
</template>

<script>
import QuestionTypeListFilter from '@/modules/question-type/components/question-type-list-filter.vue';
import QuestionTypeListTable from '@/modules/question-type/components/question-type-list-table.vue';
import QuestionTypeListToolbar from '@/modules/question-type/components/question-type-list-toolbar.vue';

export default {
  name: 'app-question-type-list-page',

  components: {
    [QuestionTypeListFilter.name]: QuestionTypeListFilter,
    [QuestionTypeListTable.name]: QuestionTypeListTable,
    [QuestionTypeListToolbar.name]: QuestionTypeListToolbar,
  },
};
</script>

<style></style>
